<script>
import TablePage from '@/found/components/table_page';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {

    };
  },
  async created() {
    await this.getConfigList('document_capture_log_list');
  },
};
</script>

<template>
  <div>
    <div>商超系统登录</div>
    <vxe-table ref="xTable" resizable
                show-overflow
                show-footer-overflow
                highlight-hover-row
                highlight-current-row
               :data="productList">
        <vxe-table-column field="checkQuantity" title="登录结果" width="110"></vxe-table-column>
        <vxe-table-column field="checkPriceTax" title="返回信息" width="110"></vxe-table-column>
      </vxe-table>
      <div>单据抓取</div>
    <vxe-table ref="xTable" resizable
                show-overflow
                show-footer-overflow
                highlight-hover-row
                highlight-current-row
               :data="productList">
        <vxe-table-column field="checkQuantity" title="单据类型" width="110"></vxe-table-column>
        <vxe-table-column field="checkPriceTax" title="原始数量" width="110"></vxe-table-column>
        <vxe-table-column field="checkPriceTax" title="抓取数量" width="110"></vxe-table-column>
        <vxe-table-column field="checkPriceTax" title="抓取成功" width="110"></vxe-table-column>
        <vxe-table-column field="checkPriceTax" title="抓取失败" width="110"></vxe-table-column>
      </vxe-table>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {

    };
  },
};
</script>

<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import formAdd from './components/formAdd.vue';

formCreate.component('formAdd', formAdd);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    const rule = await this.getFormRule('document_capture_log_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.post('/kms/grabRespDataController/query', { id: this.formConfig.id }).then((res) => {
        this.setValue({
          ...res.result,
        });
        this.row = res.result;
      });
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'orderTypeVos') {
        // 单据类型
        item.restful = '/mdm/mdmdictdata/list';
        item.restfulParams = {
          dictTypeCode: 'kms_order_type',
        };
        item.optionsKey = { label: 'dictValue', value: 'dictCode' };
        item.refresh = true;
      }
      return item;
    },
  },
};
</script>

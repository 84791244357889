var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("商超系统登录")]),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            resizable: "",
            "show-overflow": "",
            "show-footer-overflow": "",
            "highlight-hover-row": "",
            "highlight-current-row": "",
            data: _vm.productList,
          },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "checkQuantity", title: "登录结果", width: "110" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "checkPriceTax", title: "返回信息", width: "110" },
          }),
        ],
        1
      ),
      _c("div", [_vm._v("单据抓取")]),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            resizable: "",
            "show-overflow": "",
            "show-footer-overflow": "",
            "highlight-hover-row": "",
            "highlight-current-row": "",
            data: _vm.productList,
          },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "checkQuantity", title: "单据类型", width: "110" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "checkPriceTax", title: "原始数量", width: "110" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "checkPriceTax", title: "抓取数量", width: "110" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "checkPriceTax", title: "抓取成功", width: "110" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "checkPriceTax", title: "抓取失败", width: "110" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }